.search_charger_near_wrapper {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .search_prompt, .search_btn {
    flex: 1;
  }

  .search_input {
    flex: 4;

    input {
      width: 100%;
      height: 35px;
      padding: 0 20px;
      border-radius: 5px;
      border: var(--global-sub-color) 2px solid;
      color: #394852;
      font-size: 16px;
      font-weight: 300;
      line-height: 35px;
    }
  }

  .search_btn {
    height: 100%;

    button {
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      border: none;
      background-color: var(--global-sub-color);
      color: white;
      border-radius: 5px;
      padding: 0 3.5rem;
      height: 35px;
      position: relative;
      text-align: center;

      img {
        width: 25px;
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}


.user_profile_bar_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 35px 0;
  background-color: #cfdde6;
  padding: 1rem 0;

  .complete_icon {
    width: 140px;
    text-align: center;

    img {
      width: 100px;
    }
  }

  .profile_info {
    flex: 1;

    h4 {
      font-weight: 300;
      font-size: 28px;
      color: var(--global-home-color);
    }

    p {
      font-size: 18px;
    }
  }

  .profile_progress {
    width: 300px;
    padding: 0 1rem;

    .progress_indicator {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .progress_ball {
        background-color: red;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        &.active {
          background-color: #73bd3a !important;
        }
      }
    }


  }
}

.section_title {
  color: var(--global-home-color);
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 35px;

  span {
    font-size: 18px;
  }
}


.charging_stats_section {
  padding: 1rem 0 4rem 0;


  .stats_wrapper {
    display: flex;
    flex-direction: row;
    height: 400px;

    .stats_chart {
      flex: 1;
    }

    .stats_tab_wrapper {
      width: 320px;
      height: 100%;

      .tab_btns {
        display: flex;

        button {
          width: 50%;
          border: none;
          height: 40px;

          &.active {
            background-color: #cfdde6;
          }

          &:first-child {
            img {
              width: 30px;
            }
          }

          &:last-child {
            img {
              width: 45px;
            }
          }
        }
      }

      .use_energy_info {
        padding: 15px 30px;
        background-color: #cfdde6;
        height: 360px;

        .energy, .total_charge_ups {

          h1 {
            font-size: 44px;
            font-weight: 200;

            span {
              font-size: 14px;
            }
          }

          p {
            font-size: 16px;
            margin: -6px 0 13px 0;
            font-weight: 300;
          }
        }
      }
    }
  }
}


.account_activity_table_wrapper {
  padding-bottom: 100px;

  .btns_wrapper {
    button {
      margin-right: 1rem;
      padding: 0.4rem 1.2rem;
      border: 2px solid #b4c1c8;
      background-color: #FFF;
      color: #777;
    }
  }

  table {
    width: 100%;

    th {
      color: #b7c4cc;
      padding: 15px 10px;
    }

    td {
      padding: 20px 10px;
      background-color: #cfdde6;
    }
  }
}
