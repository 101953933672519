.base_input {
  width: 90%;
  border-radius: 4px;
  padding: 4px 5px;
  margin-right: 1rem;
  border: 1px solid #D9D9D9;
}

.form_check {
  height: 25px;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
  position: relative;

  .check_label_wrapper {
    cursor: pointer;
    margin-left: 5px;
    margin-bottom: 0;
    display: block;
    position: relative;
    padding-left: 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.checked {
      color: #3f3f3f;
    }


    &.disable {
      pointer-events: none;
    }

    p {
      margin-left: 30px;
      margin-bottom: 10px !important;
    }

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.form_check {
  input:checked ~ .checkmark {
    background-color: var(--global-home-color);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-right: 0.625rem;

  }
}

.form_section {
  margin-top: 1rem;

  label {
    margin-bottom: 0.5rem;
  }
}

.form_group {
  margin-bottom: 1rem;

  label {
    color: rgb(117, 117, 117);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 4px;
  }

  input {
    height: 38px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    border-bottom: 2px solid rgb(237, 237, 237);
    color: rgb(76, 76, 76);
    font-weight: 500;
    width: 100%;
    padding: 0px 8px;
  }
}

.form-group {
  select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 1px !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .dropdown {
    padding: 0;
    border: none;
    height: 40px;
    background-color: #FFF;
  }

  .dropdown-menu {
    min-width: 100%;
    right: auto;
    bottom: auto;

    &.inner {
      position: static;
      float: none;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      .active {
        a {
          color: #007A64;
          background: #F4F5F9;
        }
      }

      a {
        cursor: pointer;
        font-size: 0.875rem;
        color: var(--bright-txt-color);
        background-color: #FFF !important;

        &:hover {
          color: #007A64;
          background: #F4F5F9 !important;
        }
      }
    }
  }

}

.select_toggle {
  width: 100%;
  height: 38px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0px;
  border-bottom: 2px solid rgb(237, 237, 237);
  color: rgb(76, 76, 76);
  font-weight: 500;
  padding: 0px 8px;

  &:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
  }

  &:after {
    margin-left: 0;
  }

  .filter-option {
    position: static;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;

    .filter-option-inner {
      line-height: 36px;
    }

    .filter-option-inner-inner {
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}


.register_page_wrapper {
  .create_input_wrapper {
    margin-bottom: 1rem;

    & > label, .unit_label {
      margin-bottom: 0.5rem;
      display: inline-block;
      color: var(--bright-txt-color);
      font-weight: 600;
    }

    &.unit_input {
      position: relative;

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .unit_label {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-bottom: 0 !important;
      }
    }

    input[type=text], input[type=password], input[type=number], input[type=time] {
      height: 40px;
      border: 1px solid var(--bright-border-color);
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-weight: 400;
      line-height: 1.5;
      font-size: 0.875rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .checkbox_group {
      display: flex;
      flex-wrap: wrap;
      color: #7e7e7e;

      .create_checkbox_wrapper {
        margin-bottom: 0.5rem;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;

        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 9px;
          top: 5px;
          width: 7px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      input:checked ~ .checkmark {
        background-color: #007A64;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        margin-right: 0.625rem;


      }

      &.x {
        flex-direction: row;
        align-items: center;
      }

      &.y {
        flex-direction: column;

        .create_checkbox_wrapper {
          margin-bottom: 0.5rem;
        }
      }
    }

  }

  .select_project_manager_wrapper {
    .group_label {
      color: black;
      font-weight: bold;
    }
  }

  .create_file_uploader_wrapper {
    margin-bottom: 0.8rem;
  }

  .create_submit_btn {
    width: 100%;
    border: none;
    background-color: var(--home-bright-color);
    color: white;
    height: 45px;

    &:hover {
      background-color: var(--home-dark-color);
    }
  }

  .create_board_btn {
    background-color: var(--home-bright-color);
    color: white;
    border: none;
    padding: 0.4rem 1.4rem;

    &:hover {
      background-color: var(--home-dark-color);
    }
  }

}

.submit_btn_wrapper {
  button {
    width: 100%;
    border: none;
    background-color: var(--global-home-color);
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 0.5rem 0;


  }
}

