.navbar_wrapper {
  .main_nav {
    padding: 20px;

    .logout_btn {
      color: var(--global-home-color);
      background: none;
      border: none;
      padding: 0;
    }

    .brand_logo {
      img {
        width: 178px;
      }
    }
  }

  .nav_links {
    background: #BC2555;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      li {
        border-right: 1px solid #9faab0;

        a {
          padding: 0.7rem 2.5rem;
          font-size: 16px;
          font-weight: 400;
          color: #FFF;

          img {
            width: 25px;
            margin-right: 16px;
            fill: #FFF;
          }
        }
      }
    }
  }

  .charging_session_header, .sub_nav {
    padding: 10px 20px;
    background-color: #5B6772;
    color: #FFF;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 460px;

      li {
        margin-right: 2rem;

        a {
          color: #c8c8c8;

          &.active {
            color: #FFF;
          }
        }
      }
    }
  }

  .charging_session_header {
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
}