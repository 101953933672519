.register_page_wrapper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: scroll;
  position: relative;
  background-color: #F9F9F9;
  display: flex;
  justify-content: center;
  align-items: center;

  .register_box {
    width: 100%;
    height: 100%;
    max-width: 475px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 0;

    .create_input_wrapper, .create_file_uploader_wrapper {
      width: 100%;
    }

    .register_sub_btn {
      margin-top: 1.5rem;
      width: 100%;

      button {
        width: 100%;
        height: 40px;
        background-color: var(--global-home-color);
        color: white;
        border: none;


      }
    }

    .register_input_wrapper, .select_toggle {
      border: 1px solid var(--global-bright-color);
    }

    .select_toggle {
      height: 42px;
      line-height: 42px;
    }
  }
}