.map_console_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100%;

  .wrap_border {
    position: relative;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 600px;
  }

  .desc_bar {
    height: 60px;
    background-color: var(--global-sub-color);
    color: #FFF;
    padding: 0 10px;
    line-height: 60px;
    font-size: 16px;
    font-weight: 300;

    a {
      color: #FFF;
      text-decoration: underline;
    }
  }

  .screen_loader_main {
    z-index: 999999;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


  }

}

.side_bar_container {
  z-index: 200;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  border: 1px solid #d9d9d9;

  &.left_bar {
    left: 0;
  }

  &.right_bar {
    right: 0;
  }

  .left_side_space_main {
    width: 180px;
    background-color: #FFF;
  }

  .right_side_space_main {
    width: 380px;
    background-color: var(--global-sub-color);
  }


  .map_filter_list {
    .filter_title {
      background-color: var(--global-home-color);
      color: #FFF;
      line-height: 27px;
      font-size: 0.875rem;
    }

    .filter_title, .filter_checkbox {
      padding: 0 17px;
    }

    .filter_checkbox {
      color: #333;
      line-height: 47px;
      border-bottom: 1px solid var(--global-bright-color);
      font-size: 12px;
    }
  }

  .charger_info_panel {
    .panel_tab {
      display: flex;

      li {
        width: 25%;
        text-align: center;
        color: #FFF;
        font-size: 12px;
        border: 1px solid #545454;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        padding: 10px 0;
        cursor: pointer;

        &.active {
          background-color: #778189;
        }

        &:hover {
          opacity: 0.5;
        }

        img {
          width: 35px;
          height: 35px;
          display: block;
          margin-bottom: 0.5rem;
        }
      }
    }

    .sort_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 5px 10px;
      background-color: #778189;

      span {
        color: #FFF;
        font-weight: 300;
      }

      select {
        margin-left: 10px;
        min-width: 200px;
        height: 35px;
        font-size: 14px;
        border-radius: 5px;
        padding: 0 6px;
      }
    }


    .charge_spots_tab_wrapper {
      max-height: 470px;
      overflow: scroll;
      .spot_list {
        display: flex;
        flex-direction: column;

        li {
          cursor: pointer;
          display: flex;
          align-items: flex-start;
          padding: 10px;
          background-color: #FFF;
          border-bottom: 1px solid var(--global-bright-color);
          position: relative;
          min-height: 130px;

          .spot_level {
            width: 66px;
            flex: 0 0 66px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img, small {
              display: block;
            }

            small {
              font-size: 10px;
            }

            img {
              width: 30px;
              height: 30px;
              margin-bottom: 5px;
            }
          }

          .spot_info {
            width: 100%;
            flex: 1;
            padding-left: 10px;

            h4 {
              font-size: 16px;
              max-width: 160px;
              word-break: break-word;
              font-weight: 700;
            }

            p {
              font-size: 14px;
              font-weight: 300;
              max-width: 140px;
              word-break: break-word;
            }
          }


          .go_nav_btn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: 2px solid var(--global-sub-color);
            background-color: #FFF;
            width: 30px;
            height: 30px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}


