.page_container {
  margin: 24px auto;
  max-width: 1024px;
}

#account_page_title {
  color: var(--global-home-color);
  margin-bottom: 2rem;
}

.noti_info {
  .info {
    margin-bottom: 1rem;
  }
}

.my_account_detail_page {
  hr {
    margin: 2rem 0;
  }

  h5 {
    color: var(--global-home-color);
  }

  .login_details_wrapper {
    display: flex;
    flex-direction: row;

    .profile_car {
      background-color: #5B6772;
      width: 250px;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 10px;

      svg {
        width: 170px;
        height: 170px;
      }

      h5, span {
        margin-bottom: 0;
        color: #FFF;
      }
    }

    .info_section_wrapper {
      padding: 1rem 0;
      margin-left: 20px;
      flex: 1;
      border-top: 1px solid var(--global-bright-color);

      .info {
        width: 50%;
      }

    }
  }

  .account_promotion {
    display: flex;
    flex-direction: column;

    input {
      border: 1px solid var(--global-bright-color);
      border-radius: 5px;
      padding: 0.3rem 5px;
      min-width: 250px;
    }

    button {
      margin-left: 1.5rem;
      background-color: #FFF;
      border: 1px solid var(--global-home-color);
      border-radius: 5px;
      padding: 0.3rem 12px;
      font-size: 0.875rem;
    }
  }

  .notification_setting {
    .section_header {
      h5 {
        margin-bottom: 20px;
      }
    }

    .setting_section {
      margin-bottom: 2rem;
    }

    .notification_info {
      margin-bottom: 1rem;
    }
  }
}

.section_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  button {
    background: none;
    border: 2px solid #5B6772;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 75%;
      height: 75%;
    }
  }
}


.info_section_wrapper {
  margin-bottom: 40px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;

    .info {
      width: 33%;
      margin-bottom: 1.6rem;

      .info_value {
        margin-bottom: 0;
        min-height: 24px;
        font-size: 18px;
      }

      small {
        color: #777777;
      }

      .account_profile_form {
        justify-content: space-between;

        .filter-option-inner {
          line-height: 34px;
        }

        .input_base_wrapper {
          text-align: right;
        }

        &.mobile_phone_form {
          width: 90%;

          .form_group {
            width: 35%;
          }

          .base_input {
            margin-right: 0;
          }
        }

        .form_group {
          width: 90%;

          .select_toggle {
            border: 1px solid #D9D9D9;
            height: 34px;
            border-radius: 4px;
          }
        }
      }
    }

  }
}

.manage_Vehicle_page {
  .card_item {
    height: 150px;
  }

  .card_img {
    img {
      width: 80px;
    }
  }
}

.manage_card_page {
  .card_item {
    height: 180px;
  }

  .card_img {
    img {
      width: 60px;
    }
  }
}

.manage_card_page, .manage_Vehicle_page {
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .card_item {
      padding: 1rem;
      position: relative;
      font-weight: 400;
      font-size: 16px;
      background-color: rgb(255, 255, 255);
      color: rgb(76, 76, 76);
      box-shadow: rgb(51 50 49 / 35%) 0px 2px 6px 0px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 1rem;

      &:hover {
        box-shadow: rgb(51 50 49 / 53%) 0px 2px 6px 0px;
      }


      h5 {
        font-size: 1rem;
        margin: 0.5rem 0;
      }

      p {
        font-size: 0.8rem;
      }

      &.add_card {
        .plus_ball {
          padding: 16px;
          background: var(--global-home-color);
          border-radius: 50%;
          display: inline-block;
          width: 64px;
          height: 64px;
          max-width: 64px;
          min-width: 64px;
        }

        .add_card_btns {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-top: 1rem;

          .dialog_wrapper {
            .active_btn {
              margin-left: 0.5rem;
              font-size: 0.875rem;
              padding: 0.4rem 1.5rem;

            }

            &:first-child {
              .active_btn {
                border: 1px solid var(--global-home-color);
                background: none;
              }
            }

            &:last-child {
              .active_btn {
                border: 1px solid var(--global-home-color);
                background-color: var(--global-home-color);
                color: #FFF;
              }
            }

          }
        }
      }
    }
  }
}

.modal-title {
  font-weight: 500;
  font-size: 1.1rem;
}

.modal-footer {
  border-top: none;

  button {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    padding: 0.4rem 1.5rem;

    &:first-child {
      border: 1px solid var(--global-home-color);
      background: none;
    }

    &:last-child {
      border: 1px solid var(--global-home-color);
      background-color: var(--global-home-color);
      color: #FFF;
    }
  }
}

.card_num_select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;


  button {
    width: 35px;
    height: 35px;

    &:first-child {
      border: 1px solid rgb(217, 217, 217);
      color: rgb(117, 117, 117);
      background-color: rgb(217, 217, 217);
    }

    &:last-child {
      border: 1px solid var(--global-home-color);
      color: #FFF;
      background-color: var(--global-home-color);
    }
  }

  .card_num_txt {
    min-width: 150px;
    text-align: center;
  }
}

.request_card_dialog {
  .total_price {
    margin-top: 2rem;
  }
}

.add_Vehicle_dialog {
  .primary_check {
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    input[type=checkbox] {
      margin-right: 0.5rem;
    }
  }
}

.del_dialog_btn {
  width: 100%;
  text-align: center;
  padding: 1rem 0;

  button {
    background: none;
    border: none;
    color: #013687;
    font-size: 0.875rem;
  }
}

.account_statement_page {
  hr {
    border-width: 2px;
  }

  .select_date {
    margin-bottom: 3rem;

    .date_form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      #month_label, #year_label {
        margin-right: 0.5rem;
      }

      #year_label {
        margin-left: 0.5rem;
      }

      select {
        max-width: 250px;
      }
    }
  }

  .user_info {
    align-items: flex-start;

    .right_section {
      text-align: right;
    }

    span {
      display: block;
      font-weight: 500;

      &.up_margin {
        margin-top: 24px;
      }
    }
  }

  .sub_container {
    padding: 2rem 1rem;

    .sub_section {
      margin-bottom: 3rem;

      .sub_title {
        font-weight: 400;
        color: var(--global-home-color);
      }

      .payment_balance_list {
        border-top: 3px solid #C8C8C8;

        li {
          padding: 16px 8px;

          &:nth-child(2n) {
            background-color: #D9D9D9;
          }
        }
      }

      .payment_activity_table, .account_funding_table {
        width: 100%;
        margin-top: 1rem;

        th {
          color: #C8C8C8;
          border-bottom: 2px solid #C8C8C8;
        }

        td {
          color: #565656;
          border-bottom: 1px solid #C8C8C8;
        }

        th, td {
          padding: 16px;
        }

      }
    }


  }
}