:root {
  --bright-color: #b3b3b3;
  --border-secondary: #E6E7EB;
  --border-primary: #DCDEE0;
  --form-input: #555555;
  --home-color: #3886ff;
  --button-radius: 5px;
}


html, body {
  margin: 0;
  padding: 0;
}


a {
  text-decoration: none;
}

button {
  &:hover {
    opacity: 0.5;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.flex_row_between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_center {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.swal2-container {
  z-index: 99999 !important;
}

.scroll_section {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
}

.mb_sm{
  margin-bottom: 5px;
}