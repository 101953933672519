.auth_input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  border: none;
  border-bottom: 2px solid #e8e7e7;
  outline: none;
  color: #535353;
  border-radius: 0;

  &:disabled{
    background: rgba(128, 128, 128, 0.11);
  }
}


.console_input {
  width: 100%;
  display: inline-block;
  position: relative;

  > :nth-child(1) {
    position: absolute;
    top: 5px;
    left: 16px;
    pointer-events: none;
    font-size: 11px;
    color: gray;
    font-weight: bold;
    line-height: 18px;
    display: inline;
    z-index: 100;
  }

  input,select {
    width: 100%;
    border: 1px solid var(--border-primary);
    border-radius: 3px;
    vertical-align: middle;
    height: 50px;
    padding: 15px 15px 0 15px;
    color: var(--form-input);
    font-size: 15px;
    background-color: white;
    font-weight: inherit;

    &:disabled {
      cursor: default;
      background-color: #eeeeee;
      color: black;
      border-color: rgba(118, 118, 118, 0.3);
    }
  }

}