.login_page_wrapper {
  width: 100%;
  height: 100vh;
  position: relative;

  .login_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 7rem;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #c8c8c8;

    .input_base_wrapper {
      text-align: center;
    }

    .to_register {
      width: 100%;
      text-align: center;
      margin-top: 16px;

      p {
        color: var(--bright-txt-color);
        font-size: 0.875rem;

        a {
          margin-left: 6px;
          color: var(--global-bright-color);

          &:hover {
            color: var(--home-dark-color);
          }
        }
      }
    }

    .main_logo {
      padding: 1.5rem 0 2.5rem 0;

      img {
        width: 400px;

      }
    }

    input {
      padding: 0.2rem 0.6rem;
      color: var(--bright-txt-color);
    }

    input, button {
      height: 38px;
      margin-bottom: 16px;
      border: 1px solid var(--home-bright-color);

    }

    button, input {
      width: 100%;
      max-width: 400px;
    }

    button {
      padding: 0.2rem;
      background-color: var(--global-home-color);
      color: white;

    }

    input {
      border-bottom: 1px solid #c8c8c8;
      margin-bottom: 2rem;
    }
  }
}