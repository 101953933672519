.MuiDialogTitle-root {
  text-align: center;
}

.dialog_button_main {
  button {
    width: 50%;
    background-color: white;
    padding: 0.6rem 0;
    border: 1px solid var(--border-secondary);

    &:first-child {
      color: var(--home-color);

      &:hover {
        opacity: 1 !important;
        color: white;
        background-color: var(--home-color);
      }
    }

    &:last-child {
      color: red;

      &:hover {
        opacity: 1 !important;
        color: white;
        background-color: red;
      }
    }
  }
}

.create_project_dialog_main, .assign_worker_dialog_main, .change_member_authority_dialog_main, .add_member_dialog_main, .work_modify_dialog_main, .work_add_dialog_main {
  .dialog_content {
    width: 350px;
    padding: 1rem;

    input, select {
      padding: 1rem 0.3rem;
      border: 1px solid var(--border-secondary);
      width: 100%;
    }
  }
}

.worker_info_dialog_main {
  .close_btn_main {
    padding: 0 1rem;
    margin-bottom: 1rem;

    button {
      width: 100%;
      padding: 0.5rem 0;
      background-color: var(--home-color);
      color: white;
      border: none;
      border-radius: 5px;
    }
  }
}

.assign_worker_dialog_main {
  .assign_worker_form {
    select {
      margin-top: 1rem;
    }
  }
}


.work_add_dialog_main {
  .dialog_content {
    width: 100vw !important;
    max-width: 600px;

    .work_form {
      margin-bottom: 1rem;
    }
  }
}

.work_modify_dialog_main {
  .work_form {
    margin-bottom: 1rem;

    label {
      font-size: 0.8rem;
      color: gray;
    }
  }
}

.create_project_dialog_main {
  .project_status_list {
    span {
      cursor: pointer;
      padding: 0.6rem 1rem;
      font-size: 0.875rem;
      border: 1px solid var(--border-secondary);
      border-radius: 30px;
      background-color: white;

      &:hover, &.active {
        background-color: var(--home-color);
        color: white;
      }
    }
  }
}

.change_work_status_dialog_main {
  .dialog_content {
    max-width: 800px;
    padding: 1rem;
    width: 100vw;

  }


  .work_status_list {
    padding: 1rem;

    span {
      cursor: pointer;
      padding: 0.6rem 1rem;
      font-size: 0.875rem;
      border: 1px solid var(--border-secondary);
      border-radius: 30px;
      background-color: white;

      &:hover, &.active {
        background-color: var(--home-color);
        color: white;
      }
    }
  }

  .work_status_change_form_main {
    padding: 1rem;

    .reason_input {
      width: 100%;
      height: 50px;
      border: 1px solid var(--border-secondary);
      border-radius: 5px;
      padding: 0 8px
    }
  }

  .work_status_image_uploader_main {
    padding: 1rem;

    .upload_image_form {
      .upload_img_list {
        padding: 1rem 2rem;
        border: 1px solid var(--border-secondary);
        min-height: 200px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .image_item_btn_main {
          margin-top: 1rem;

          button {
            background-color: white;
            border-radius: 5px;

            &:first-child {
              margin-right: 0.5rem;
              color: var(--home-color);
              border: 1px solid var(--home-color);
            }

            &:last-child {
              margin-left: 0.5rem;
              color: red;
              border: 1px solid red;
            }
          }
        }
      }

      .add_btn {
        margin-top: 2rem;
        width: 100%;
        padding: 0.5rem 0;
        border-radius: 5px;
        background-color: var(--home-color);
        color: white;
        border: none;
      }
    }
  }
}


.change_member_authority_dialog_main {
  .delete_member_btn {
    text-align: right;
    margin-bottom: 0.5rem;
    width: 100%;

    button {
      width: 100%;
      border-radius: 5px;
      color: red;
      border: 1px solid red;
      background-color: white;
      padding: 0.34rem;
    }
  }
}


.add_member_dialog_main {
  .member_authority_list {
    padding: 0.5rem 0;

    span {
      cursor: pointer;
      padding: 0.6rem;
      font-size: 0.875rem;

      &:hover, &.active {
        background-color: var(--home-color);
        color: white;
      }
    }
  }
}


.station_info_dialog_wrapper {
  .modal-body {
    padding: 0;
  }

  .align_content {
    padding-left: 27px;
  }

  hr {
    margin: 0.5rem 0;
  }

  .info, .price, .tip {
    padding: 1rem;
  }

  .sm_title {
    display: flex;

    img {
      width: 20px;
      height: 20px;
    }

    p {
      margin-left: 8px;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .info {
    align-items: center;

    h5 {
      font-size: 1rem;
      margin: 0;
    }

    .cs_addr {
      font-size: 0.875rem;
      margin: 0;
    }

    #my_spot_btn {
      background: none;
      border: none;

    }
  }

  .price {
    span {
      display: block;
      font-size: 0.875rem;
      margin-bottom: 0.2rem;
    }
  }

  .tip {
    #leave_tip_btn {
      margin-top: 1rem;
      width: 100%;
      height: 36px;
      border: 1px solid var(--global-home-color);
      background: none;
    }

    .tip_list {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      max-height: 500px;
      overflow-y: scroll;

      .tip_item {
        padding: 0.5rem;
        margin-bottom: 1rem;
        border-radius: 5px;
        background-color: #efefef;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 80px;


        .comment_function {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;

          button {
            background: none;
            border: none;
            font-size: 12px;
            color: var(--bright-txt-color);
          }
        }

        p {
          display: block;
          color: #202020;
          font-size: 0.875rem;
          word-break: break-all;
          white-space: nowrap;

          &.name {
            font-weight: 500;
            margin-bottom: 0.5rem;
          }

          &.content {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }


}

.edit_tip_dialog_wrapper {
  textarea {
    width: 100%;
    min-height: 120px;
    outline-color: var(--global-home-color);

    &:focus {
      background-color: #f1f1f1;
    }
  }
}